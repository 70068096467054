<template>
  <div id="rzp-modal" class="rzp-modal-container">
    <div class="rzp-center">
      <Loading v-if="loading" />

      <div v-else class="rzp-modal">
        <div class="rzp-close-container">
          <img
            class="rzp-close-icon"
            src="@/assets/icons/ico-close-dark.svg"
            @click="closeModal()"
          />
        </div>
        <div class="rzp-modal-body">
          <div class="rzp-row">
            <div class="rzp-plan-details">
              <OrderSummary
                v-if="orderDetail && orderDetail.detail"
                :couponCodeProp="couponCode"
                :orderDetails="orderDetail"
                @totalAfterDiscount="totalAfterDiscount"
              ></OrderSummary>
            </div>

            <div class="rzp-payment-details" v-if="paymentModes.length > 0">
          
              <!-- <img src="@/assets/icons/razorpay_banner.svg"  class="razorpay-banner" alt="" v-if="paymentModes[selectedIndex].key !== 'paypal'" /> -->
              <!-- <p class="rzp-bold-text rzp-fs-bold" v-if="paymentModes[selectedIndex].key !== 'paypal'">Subscribe Now</p> -->
              <!--
              <p class="rzp-bold-text rzp-fs-bold">{{ $t("Select Payment Mode") }}</p>

              -->
              <div v-for="(paymentMode, index) in paymentModes" :key="index">
                <SelectPaymentMethod
                  :data="paymentMode"
                  :index="index"
                  :icon="paymentMode.icon"
                  @onSelectItemChange="changeItem"
                  class="rzp-mt-default"
                />
              </div>

              <div class="rzp-mt-max">
              <paypalSub v-if="paymentModes[selectedIndex].key === 'paypal'" gatewaydetail="PAYPALSUB" :paymentDetail="orderDetail"></paypalSub>

                <button v-else @click="initiatePayment" class="button-primary">
                  {{ isPaynowEnvoke }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import { showSnackBar } from "@/utilities";
import { mapGetters, mapActions } from "vuex";
import { store } from "@/store/store";

import {
  errorHandlerMiddleware,
  setHeader,
} from "../../../store/helper";

export default {
  props: ["onOpen", "onClose", "plan"],
  data() {
    return {
      loading: false,
      couponCode: "",
      orderDetail: null,
      couponInformation: 0,
      paymentModes: [],
      selectedIndex: 0,
      referencedata: null,
      referenceid: null,
      isPaynowEnvoke:"",
      userInfoDetails: {}
    };
  },
  created() {

    let stripeplan = this.appConfig.planmappinggateway && this.appConfig.planmappinggateway['stripe'] ? this.appConfig.planmappinggateway['stripe'] : ["HO3UWDHJ", "FKWGGMDU", "UBHV8NM4", "4ENEBGTS", "FHA2UVO4", "WR6KIZNT", "WR6KIZNT", "7JOLYPFO", "KINGAERA", "OVRIEZPO", "SXNPW0K6"];
    let razorpayplan = this.appConfig.planmappinggateway && this.appConfig.planmappinggateway['razorpay'] ? this.appConfig.planmappinggateway['razorpay'] : ["3FBAWGDE","3HMAPSMJ","ANLVXSIM","BPLBFL8V","EGW0F7KH","HGRWBVSC","QZJN8SZE","UZS0AAFA"];
    let paypalplan = this.appConfig.planmappinggateway && this.appConfig.planmappinggateway['paypal'] ? this.appConfig.planmappinggateway['paypal'] : ["WR6KIZNT","WB942GUD"];
    this.isPaynowEnvoke = this.$t("PAY NOW")

    
    this.paymentModes = [
        {
          title: "UPI",
          description: "Pay with your UPI ID",
          icon: "upi.svg",
          active: true,
          key: "UPI",
          alias: "upi",
          gatewayid: "RAZORPAY",
          planid: razorpayplan

        },
        {
          title: "Cards",
          description: "Use your credit card or debit card to make the payment",
          icon: "credit_card.svg",
          active: false,
          key: "CC",
          alias: "card",
          gatewayid: "RAZORPAY",
          planid: razorpayplan

        },
      // {
      //     title: "SBI",
      //     description: "Use your credit card or debit card to make the payment",
      //     icon: "credit_card.svg",
      //     active: true,
      //     key: "CC",
      //     alias: "card",
      //     gatewayid: "SBIEPAY",
      //     planid: razorpayplan

      //   },
     
        {
          title: "Netbanking",
          description: "Use your net banking account to make the payment",
          icon: "net_banking.svg",
          active: false,
          key: "NB",
          alias: "netbanking",
          gatewayid: "RAZORPAY",
          planid: razorpayplan
        },
        {
          title: "Wallet",
          description: "Use your Wallet to make the payment",
          icon: "wallet.svg",
          active: false,
          key: "WA",
          alias: "wallet",
          gatewayid: "RAZORPAY",
          planid: razorpayplan
        },
        // {
        //   title: "International Cards (Stripe)",
        //   description: "Use your Stripe account to make the payment",
        //   icon: "stripe.png",
        //   active: false,
        //   key: "STRIPE",
        //   alias: "stripe",
        //   gatewayid: "STRIPE",
        //   planid: stripeplan
        // },
        // {
        //   title: "Paypal",
        //   description: "Use your PayPal account to make the payment",
        //   icon: "paypal.png",
        //   active: true,
        //   key: "paypal",
        //   alias: "paypal",
        //   gatewayid: "PAYPALSUB",
        //   planid: paypalplan
        // },
      ],
    eventBus.$on("initiate-razorpay-init-response", (data) => {
      if (data.reason && data.errorcode) {
        showSnackBar(data.reason);
        this.loading = false;
        let currentLanguage = this.getCurrentLanguageUrlBase();
        this.$router.push({name: "Home", params: {lang: currentLanguage }});
      } else {
        this.initiateSdk(data);
      }
     
    });
    eventBus.$on("razorpay-callback-response", (data) => {
        
        setTimeout(() => {
          this.closeModal();
        
          this.loading = false;
          showSnackBar(this.$t("Payment Successful"));

          eventBus.$emit("successfullpayment-event", this.orderDetail.detail);

        }, this.appConfig.razorpaySuccessCounter || 20000);

    });

  
  },
  beforeDestroy() {
    eventBus.$off("initiate-razorpay-init-response");
    eventBus.$off("razorpay-callback-response");
  },
  ...mapActions(["availabilityDetail"]),
  methods: {

    listPaymentGateways() {
      this.userInfoDetails = localStorage.getItem("subscriberDetails") ? JSON.parse(localStorage.getItem("subscriberDetails")).data : [];

      console.log('paymentModes', this.paymentModes);
      let payload = { paypalsub: "YES" , country: this.userInfoDetails.subscribercountry};
      store
        .dispatch("listPaymentGateways", payload)
        .then((response) => {
          if (response.data) {
            let result = response.data.data;
            let gatewayList = []
            for (let gateway of result) {
              
              for (let storeItem of this.paymentModes) {
                if (storeItem.gatewayid == gateway.gatewayid) {
                    let totalPlanList = []
                      for (let planInfo of gateway.planMapping) {
                        totalPlanList.push(planInfo.planid);
                      }
                      storeItem.planid = totalPlanList;
                    gatewayList.push(storeItem);
                }
              }
            }

            // gatewayList[0].active = true;

            this.paymentModes = gatewayList;
            this.changeItem(this.selectedIndex);
          }
        }).catch((error) => console.log(error));
    },
    totalAfterDiscount (coupon) {
      console.log(coupon)
      this.couponInformation = coupon;
    },
    changeItem(index) {
      this.selectedIndex = index;
      let data = this.paymentModes;
      let updatedArr = [];
      for (var i = 0; i < data.length; i++) {
        let mode = data[i];
        if (i == index) {
          mode.active = true;
        } else {
          mode.active = false;
        }
        updatedArr.push(mode);
      }
      this.paymentModes = updatedArr;
    },
    closeModal() {
      this.onClose();
    },
    initiatePayment() {
      this.loading = true;
      let transactionMode = this.paymentModes[this.selectedIndex]?.key;
      let gateWayType = this.paymentModes[this.selectedIndex]?.gatewayid;
      console.log('transactionMode', transactionMode, gateWayType)
      switch (gateWayType) {
        case "PAYPALSUB" :
          
          break;
        case "STRIPE" :
          this.initiateStripe();
          break;

        case "SBIEPAY":
          this.initiateSBIPAY();
        break;
        case "RAZORPAY" :
          var orderDetail = this.orderDetail;
          orderDetail.transactionMode = transactionMode;
          orderDetail.devicetype = "WEB";
          orderDetail.transactionpurpose = "SUBSCRIPTION";
          orderDetail.availabilityid = this.plan.availabilityid;
          // localStorage.setItem("paymentGetwaySaveUrl", location.href)

          if (this.plan.rental) {
            orderDetail.transactionpurpose = this.plan.rental;
            orderDetail.objectid = this.plan.objectid;
            orderDetail.priceclassid = this.plan.priceclassid;
          }
          eventBus.$emit("initiateRazorpayPayment", orderDetail);
          break;
        default:
          
          break;
      }
      // if(transactionMode === "paypal"){
        
      //   return;
      // }
      // if(transactionMode === "STRIPE"){
      //   this.initiateStripe();
      // }
      // if(this.paymentModes[this.selectedIndex].gatewayid === "RAZORPAY"){
      //   let orderDetail = this.orderDetail;
      //   orderDetail.transactionMode = transactionMode;
      //   orderDetail.devicetype = "WEB";
      //   orderDetail.transactionpurpose = "SUBSCRIPTION";
      //   eventBus.$emit("initiateRazorpayPayment", orderDetail);
      // }
    },
    initiateSBIPAY () {
      var urlencoded = new URLSearchParams();
        let orderDetail = this.orderDetail;

        let callbackurl = ''
        if (localStorage.getItem('setDisplayLanguageCode') == 'eng') {
          callbackurl = window.location.origin ;
        } else {
          callbackurl = window.location.origin + "/" + localStorage.getItem('setDisplayLanguageCode');
        }
     
        sessionStorage.setItem("order-success-details", JSON.stringify(this.orderDetail.detail));
        urlencoded.append("devicetype", orderDetail.devicetype ? orderDetail.devicetype : "WEB");
        if (this.couponInformation && this.couponInformation.isCoupon) {
          urlencoded.append("amount", this.couponInformation.totalFinalCost);
          urlencoded.append("couponid", this.couponInformation.couponCode);
        } else {
          urlencoded.append("amount", orderDetail.detail.amount);
        }
       
        urlencoded.append("currency", orderDetail.detail.currency);

        if (this.plan.rental) {
          urlencoded.append("objectid", this.plan.objectid);
          urlencoded.append("priceclassid", this.plan.priceclassid);
          urlencoded.append("availabilityid", this.plan.availabilityid);
          urlencoded.append("transactionpurpose", this.plan.rental);
        } else {

          urlencoded.append("transactionpurpose", "SUBSCRIPTION");
          urlencoded.append("availabilityid", this.plan.availabilityset && this.plan.availabilityset[0] ? this.plan.availabilityset[0] :  this.plan.availabilityid);

        }


        urlencoded.append("transactionmode", "CC");
        urlencoded.append("callbackUrl", callbackurl)
        urlencoded.append("planid", orderDetail.detail.planid);
        
        let stripe_url = this.appConfig.providerDetails.baseURLs.vCHARGE+'subscriber/v1/payment/init/SBIEPAY';
        fetch(stripe_url, 
          {        
            method: "POST",
            body: urlencoded,
            headers: setHeader(),
          }).then(errorHandlerMiddleware)
        .then((data) => {
          console.error("data ===> SBIEPAY", data);

          this.loading = false;
          let referencedata = data.referencedata;
          localStorage.setItem("referencedata", JSON.stringify(referencedata))

          let obj = "https://cspace.tv/sbi.html?EncryptTrans=" + referencedata.encRequest + "&merchIdVal=" + referencedata.merchantid;
          window.location.href = obj;

        })
        .catch((error) => {
          console.error(error);
          this.loading = false;

        });
        
    },
    initiateStripe() {
        var urlencoded = new URLSearchParams();
        let orderDetail = this.orderDetail;
        let currentDomain = window.location.origin;
        let curren_url = currentDomain + "/profile/plans" + "?" + "planid=" + this.orderDetail.detail.planid;

        let callbackurl = ''
        if (localStorage.getItem('setDisplayLanguageCode') == 'eng') {
          callbackurl = window.location.origin ;
        } else {
          callbackurl = window.location.origin + "/" + localStorage.getItem('setDisplayLanguageCode');
        }
     
        sessionStorage.setItem("order-success-details", JSON.stringify(this.orderDetail.detail));
        urlencoded.append("devicetype", orderDetail.devicetype? orderDetail.devicetype : "WEB");
        if (this.couponInformation && this.couponInformation.isCoupon) {
          urlencoded.append("amount", this.couponInformation.totalFinalCost);
          urlencoded.append("couponid", this.couponInformation.couponCode);

        } else {
          urlencoded.append("amount", orderDetail.detail.amount);
        }
       
        urlencoded.append("currency", orderDetail.detail.currency);

        if (this.plan.rental) {
          urlencoded.append("objectid", this.plan.objectid);
          urlencoded.append("priceclassid", this.plan.priceclassid);
          urlencoded.append("availabilityid", this.plan.availabilityid);
          urlencoded.append("transactionpurpose", this.plan.rental);
        } else {

          urlencoded.append("transactionpurpose", "SUBSCRIPTION");
          urlencoded.append("availabilityid", this.plan.availabilityset && this.plan.availabilityset[0] ? this.plan.availabilityset[0] :  this.plan.availabilityid);

        }


        urlencoded.append("transactionmode", "CC");
        urlencoded.append("callbackUrl", callbackurl)
        urlencoded.append("planid", orderDetail.detail.planid);
        
        let stripe_url = this.appConfig.providerDetails.baseURLs.vCHARGE+'subscriber/v1/payment/init/stripe';
        fetch(stripe_url, 
          {        
            method: "POST",
            body: urlencoded,
            headers: setHeader(),
          }).then(errorHandlerMiddleware)
        .then((data) => {
          console.error("data", data);
          // showSnackBar(data.reason);
          data?.referencedata && window.open(data.referencedata.checkoutUrl, "_self");
          this.loading = false;
          localStorage.setItem("referencedata", JSON.stringify(data.referencedata))
        })
        .catch((error) => {
          console.error(error);
        });
        
      },
      initiateSdk(data) {
        let sdkScript = document.getElementById("rdp-sdk-script");
        if (sdkScript) {
          this.configureRazorpay(data);
        } else {
          sdkScript = document.createElement("script");
          sdkScript.src = "https://checkout.razorpay.com/v1/checkout.js";
          sdkScript.id = "rzp-sdk-script";
          document.body.appendChild(sdkScript);
          sdkScript.onload = () => {
            this.configureRazorpay(data);
          };
        }
      },

      configureRazorpay(payload) {
        let data = payload.referencedata;
        this.referencedata = {...data};
        this.referenceid = payload.referenceid;
        this.loading = false;

        let key = "subscriptionid";
        let keyAlias = "subscription_id";
        let value = data[key];  
        if(value === undefined){
          key = "orderid";
          keyAlias = "order_id";
          value = data[key];
        }
        var options = {
          key: "rzp_live_AWS7mJqu49FnF5",
          name: "keetv",
          description: "keetv",
          amount: data.amount,
          currency: data.currency,
          
          config: {
            display: {
              blocks: {
                banks: {
                  name: this.paymentModes[this.selectedIndex].title,
                  instruments: [
                    {
                      method: this.paymentModes[this.selectedIndex].alias,
                    },
                  ],
                },
              },
              sequence: ["block.banks"],
              preferences: {
                show_default_blocks: false,
              },
            },
          },
          handler: function(response) {
            let referencedata = this.referencedata;
            let referenceid = this.referenceid;
            referencedata.payment_id = response.razorpay_payment_id;
            referencedata.subscription_id = response.razorpay_subscription_id;
            referencedata.razorpay_signature = response.razorpay_signature;
          // referencedata[key] = value;
            let callbackPayload = {
              referenceid: referenceid,
              referencedata: referencedata,
            };
            
            this.loading = true;
            eventBus.$emit("razorpay-payment-success-callback", callbackPayload);
          }.bind(this),
        };
        options[keyAlias] = value;
        var rzpSdk = new Razorpay(options);
        rzpSdk.open();
        rzpSdk.on("payment.failed", function(response) {
        //  alert("on error");
        this.loading = false;
        showSnackBar("Payment failed, please try again");
        }.bind(this));

      },
  },

  watch: {
    onOpen(isOpen, oldIsoOpen) {
      var modalObj = document.getElementById("rzp-modal");
      if (isOpen) {
        modalObj.style.display = "flex";
        this.orderDetail = {
          state: true,
          detail: this.plan,
          gateways: [],
          transactionPurpose: "SUBSCRIPTION",
          coupon: this.plan.coupon ? this.plan.coupon : null,
        };
      } else {
        modalObj.style.display = "none";
        this.orderDetail = null;
      }
    },
    plan () {
      this.listPaymentGateways();

    //   for (let index in this.paymentModes) {

    //     if (this.paymentModes[index].planid.includes(this.plan.planid)) {
    //       gatewayList.push(this.paymentModes[index])
    //     }
    // }
    //   if (this.plan.currency == 'USD') {
    //     gatewayList[0] = this.paymentModes.pop();
    //   } else {
    //     // this.paymentModes.pop();
    //     gatewayList = this.paymentModes;
    //   }

    // if (gatewayList.length) {
    //   if ( gatewayList[0].key == "STRIPE" ) {
    //     gatewayList[0].active = true;
    //   }

    //   this.paymentModes = gatewayList;
  

  }
  },
  computed : {
    ...mapGetters(["appConfig"]),
  },
  components: {
    OrderSummary: () => import("@/components/Popups/Payment/OrderSummary.vue"),
    SelectPaymentMethod: () => import("@/components/utils/SelectCard.vue"),
    paypalSub: () => 
      import(/* webpackChunkName: "paypalSub" */ "@/components/gateways/paypalSub.vue"),
    Loading: () => import( /* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),
  },
};
</script>

<style lang="scss" scoped>
  @import "@/sass/_variables.scss";
  @import "./RzpPayment.scss";
</style>
